import React, { FC, Fragment } from 'react';
import { Backdrop, CircularProgress, Stack, Typography, Theme } from '@mui/material';

type LoaderProps = {
	backdrop?: boolean;
	loadingText?: string;
	minHeight?: number;
	textColor?: string;
};

export const Loader: FC<LoaderProps> = ({
	backdrop = true,
	loadingText,
	minHeight,
	textColor = 'inherit',
}) => {
	const loader = (
		<Stack
			gap={2}
			justifyContent="center"
			alignItems="center"
			color={(theme: Theme) => theme.palette.primary.main}
			minHeight={minHeight}
		>
			<CircularProgress color="inherit" />
			<Typography textAlign="center" maxWidth="340px" color={textColor}>
				{loadingText || 'Loading...'}
			</Typography>
		</Stack>
	);
	return (
		<Fragment>
			{backdrop ? (
				<Backdrop
					sx={{
						backgroundColor: 'transparent',
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open
				>
					{loader}
				</Backdrop>
			) : (
				loader
			)}
		</Fragment>
	);
};
