import { Theme } from '@mui/material';

const styles = {
	article: {
		wordBreak: 'break-word',
		'& p': {
			wordBreak: 'break-word',
			lineHeight: '28px',
			color: '#49545B',
			fontSize: '16px',
			fontWeight: 300,
			fontFamily: 'Inter',
			marginBottom: '15px',
		},
		'& blockquote': {
			wordBreak: 'break-word',
			lineHeight: '28px',
			color: '#49545B',
			fontSize: '16px',
			fontWeight: 300,
			fontFamily: 'Inter',
		},
		'& a': {
			color: (theme: Theme) => theme.palette.primary.main,
		},
		'& h1': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '32px',
			color: 'text.primary',
			fontFamily: 'Poppins',
			fontSize: '28px !important',
		},
		'& h2': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '32px',
			color: 'text.primary',
			fontFamily: 'Poppins',
			fontSize: '24px !important',
		},
		'& h3': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '32px',
			color: 'text.primary',
			fontFamily: 'Poppins',
			fontSize: '19px !important',
		},
		'& h4': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '24px',
			color: 'text.primary',
			fontFamily: 'Poppins',
			fontSize: '16px !important',
		},
		'& h5': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '24px',
			color: 'text.primary',
			fontFamily: 'Poppins',
			fontSize: '13px !important',
		},
		'& h6': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '20px',
			color: 'text.primary',
			fontFamily: 'Poppins',
			fontSize: '11px !important',
		},
		'& ol': {
			padding: '20px 40px',
		},
		'& ul': {
			padding: '20px 40px',
		},
		'& li': {
			lineHeight: '28px',
			color: 'text.primary',
			fontSize: '16px',
			fontWeight: 300,
			fontFamily: 'Inter',
		},
		'& img.img-margin': {
			margin: '20px 0',
			maxWidth: '100%',
		},
		'& figcaption': {
			wordBreak: 'break-word',
			lineHeight: '28px',
			color: '#49545B',
			fontSize: '16px',
			fontWeight: 300,
			fontFamily: 'Inter',
		},
		'& table': {
			width: '100%',
			margin: '20px 0',
			tableLayout: 'fixed',
			borderCollapse: 'collapse',
		},
		'& thead': {
			backgroundColor: '#69a6b4',
			textAlign: 'left',
		},
		'& thead p': {
			color: '#fff',
			fontWeight: 700,
		},
		'& th, & td': {
			border: '1px solid #333',
			padding: '0.5rem',
			verticalAlign: 'top',
		},
	},
	userAvatar: {
		width: 150,
		height: 150,
		fontSize: 50,
		margin: 'auto',
		position: 'relative',
	},
};

export default styles;
