import { useCallback } from 'react';
import { useAxios } from '@/hooks/useAxios';
import { SearchPayloadType, BulkUpdateType } from '@/utils/types';

export const useUserService = () => {
	const { get, post, patch, remove, isLoading } = useAxios();

	const getUsers = useCallback(
		(query?: Record<string, any>) => get('/users', query),
		[get],
	);

	const getAllEmails = useCallback(() => get('/users/all-emails'), [get]);

	const getAllSuperAdmins = useCallback(() => get('/users/super-admins'), [get]);

	const getMentionableUsers = useCallback(
		(query: Record<string, any>) => get('/users/mentionable', query),
		[get],
	);

	const searchUsers = useCallback(
		(query: SearchPayloadType) => get('/users/search', query),
		[get],
	);

	const createUsers = (data: Record<string, unknown>) => post('/users/bulk', data, null);

	const createUser = (data: Record<string, unknown>) => post('/users', data, null);

	const updateUser = (id: string, data: Record<string, unknown>) =>
		patch(`/users/${id}`, data);

	const deleteUser = (id: string) => remove(`/users/${id}`);

	const setDefaultAccount = useCallback(
		() => patch('/users/set-default-account', {}, null),
		[patch],
	);

	const changePassword = (data: Record<string, unknown>, msg: string) =>
		patch('/users/change-password', data, msg);

	const getUsersByEntityIds = useCallback(
		(query: Record<string, unknown>) => get('/users/common-users', query),
		[get],
	);

	const sendResetPasswordLink = (email: string, message: string) =>
		post('/users/send-reset-password-link', { email }, message);

	const updateUsers = (reqData: BulkUpdateType) => patch('/users', reqData);

	return {
		getUsers,
		getAllEmails,
		getAllSuperAdmins,
		getMentionableUsers,
		deleteUser,
		createUsers,
		createUser,
		updateUser,
		searchUsers,
		setDefaultAccount,
		changePassword,
		getUsersByEntityIds,
		sendResetPasswordLink,
		updateUsers,
		isLoadingUsers: isLoading,
	};
};
