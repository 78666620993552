import React, { FC, Fragment, useState } from 'react';
import Image from 'next/image';
import { slice } from 'lodash';
import { Avatar, Badge, AvatarGroup as MuiAvatarGroup, Tooltip } from '@mui/material';
import { UserAvatar } from './UserAvatar';
import GroupIcon from '@/assets/icons/Group.svg';
import { USER_STATUS } from '@/utils/enums';
import { GroupType, UserType } from '@/utils/types';

type AvatarGroupProps = {
	customStyle?: Record<string, any>;
	items: Record<string, any>[]; // Array of users or groups
	maxItems?: number;
	withBadge?: boolean;
};

const styles = {
	avatarContainer: {
		width: '100%',
		justifyContent: 'start',
		'& .MuiAvatar-root': {
			height: '28px',
			width: '28px',
			fontSize: '12px',
		},
	},
	badge: {
		'& .MuiBadge-badge': {
			backgroundColor: '#44b700',
			boxShadow: '0 0 0 1px #FFF',
		},
	},
};

// Generates a string representation of a group with its users.
const groupWithUsers = (group: GroupType) => {
	const { name, users = [] } = group;

	if (!users.length) return name;

	const formattedUsers = users.map(
		({ userName, status }) =>
			`- ${userName}${status === USER_STATUS.NOT_INVITED ? ' (not invited)' : ''}`,
	);

	return `${name}\n${formattedUsers.join('\n')}`;
};

const avatarWithTooltip = (item: Record<string, any>) => {
	if (item.name) {
		return (
			<Tooltip
				title={groupWithUsers(item as GroupType)}
				componentsProps={{ tooltip: { sx: { whiteSpace: 'pre-line' } } }}
			>
				<Avatar style={{ width: 28, height: 28 }}>
					<Image src={GroupIcon} alt="Group" />
				</Avatar>
			</Tooltip>
		);
	} else {
		return (
			<UserAvatar
				customStyle={{ height: 28, width: 28, fontSize: 12 }}
				tooltip
				user={item as UserType}
			/>
		);
	}
};

export const AvatarGroup: FC<AvatarGroupProps> = ({
	items,
	maxItems = 4,
	customStyle = {},
	withBadge = false,
}) => {
	const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

	const showRemainingItems = () => {
		return slice(items, maxItems - 1, items.length)
			.map((item) => (item.name ? groupWithUsers(item as GroupType) : item.userName))
			.join('\n');
	};

	return (
		<Tooltip
			open={tooltipOpen}
			title={showRemainingItems()}
			componentsProps={{ tooltip: { sx: { whiteSpace: 'pre-line' } } }}
		>
			<MuiAvatarGroup
				max={maxItems}
				sx={{ ...styles.avatarContainer, ...customStyle }}
				slotProps={{
					additionalAvatar: {
						onMouseEnter: () => setTooltipOpen(true),
						onMouseLeave: () => setTooltipOpen(false),
					},
				}}
			>
				{items.length > 0 &&
					items.map((item) => (
						<Fragment key={item.id}>
							{withBadge ? (
								<Badge
									overlap="circular"
									anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
									variant="dot"
									sx={styles.badge}
								>
									{avatarWithTooltip(item)}
								</Badge>
							) : (
								avatarWithTooltip(item)
							)}
						</Fragment>
					))}
			</MuiAvatarGroup>
		</Tooltip>
	);
};
