import React from 'react';
import { LoadingButton as MuiButton } from '@mui/lab';
import { Theme } from '@mui/material';

type ButtonPropsType = {
	variant?: 'text' | 'outlined' | 'contained';
	text: string;
	loading?: boolean;
	customStyles?: Record<string, any>;
	onClick?: (event: React.SyntheticEvent) => void;
	disabled?: boolean;
	color?: 'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
	size?: 'small' | 'medium' | 'large';
	fullWidth?: boolean;
	startIcon?: React.ReactNode;
};

const makeStyles = ({ color }: { color?: string }) => ({
	button: {
		textTransform: 'capitalize',
		lineHeight: '16px',
		padding: '15px 20px',
		fontWeight: 600,
		fontSize: '13px',
		...(color === 'secondary' && {
			color: (theme: Theme) => theme.palette.text.primary,
			border: (theme: Theme) => `1px solid ${theme.borderColor.button}`,
			'&:hover': {
				border: (theme: Theme) => `1px solid ${theme.borderColor.button}`,
			},
		}),
	},
});

export const Button = ({
	variant = 'contained',
	text = '',
	loading = false,
	customStyles,
	color,
	size = 'medium',
	fullWidth = false,
	...props
}: ButtonPropsType) => {
	const styles = makeStyles({ color });
	return (
		<MuiButton
			sx={{ ...styles.button, ...customStyles }}
			variant={variant}
			loading={loading}
			color={color}
			size={size}
			fullWidth={fullWidth}
			disableElevation
			{...props}
		>
			{text}
		</MuiButton>
	);
};
